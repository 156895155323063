import {
  formatGoodsProduct,
  formatCatalogBFFListingGood,
} from "@magnit/unit-catalog/src/utils/converter";
import type { ICatalogBFFListingGood, IGoodsProduct } from "@magnit/unit-catalog/src/types";
import type {
  ITransformedProductsResponse,
  IOriginalProductsByIdsResponse,
  ITransformedProductsByIdsResponse,
  IRequestGoodsFilters,
  IGoodsFilters,
  ICatalogBFFDetailedGood,
  CatalogBFFGoodsSearchV2Response,
  ICatalogBFFGoodsSearchV2Params,
} from "~/typings/api/goods";
import { urls } from "~/api/config";
import { API_CATALOG_TYPE } from "~/constants/api";

export default () => {
  const storesStore = useStoresStore();

  const requestProducts = (
    params: Ref<ICatalogBFFGoodsSearchV2Params> | ICatalogBFFGoodsSearchV2Params,
    key?: string,
    immediate = true,
    watch = true,
  ) => {
    const transform = (
      data: CatalogBFFGoodsSearchV2Response,
      currentCategoryId?: number | string,
    ): ITransformedProductsResponse => {
      const appendUrl = (
        product: ICatalogBFFListingGood,
        categoryId?: number | string,
      ) => {
        let url = `${Routes.Product}/${product.id}-${product.seoCode}?shopCode=${storesStore.requestShopCode}&shopType=${API_REQUEST_SHOP_TYPE}`;
        if (categoryId) url += `&category=${categoryId}`;
        return { ...product, url };
      };

      return {
        goods:
          data.items
            ?.map((product) =>
              appendUrl(product, currentCategoryId),
            )
            .map((product) => formatCatalogBFFListingGood(product)) || [],
        pagination: {
          total: data.pagination.totalCount,
          pageSize: data.pagination.limit,
          page: Math.ceil((data.pagination.offset || 0) / (data.pagination.limit || 1)) + 1,
        },
      };
    };

    const body = computed(() => {
      return {
        ...toValue(params),
        includeAdultGoods: true,
        storeCode: storesStore.requestShopCode,
        storeType: String(API_REQUEST_SHOP_TYPE),
        catalogType: String(toValue(params).catalogType || API_CATALOG_TYPE),
      };
    });

    return useTransport<CatalogBFFGoodsSearchV2Response, ITransformedProductsResponse>(
      urls.goods.products,
      {
        method: "POST",
        body,
        transform,
        key,
        immediate,
        watch: watch ? [body] : false,
      },
    );
  };

  const requestProductById = (itemId: string) => {
    const shopCode = storesStore.requestShopCode;
    const url = `${urls.goods.product}${itemId}/stores/${shopCode}`;

    return useTransport<ICatalogBFFDetailedGood>(url, {
      lazy: import.meta.client,
      query: computed(() => ({
        storetype: API_REQUEST_SHOP_TYPE,
        catalogtype: API_CATALOG_TYPE,
      })),
      onResponseError(ctx) {
        if (import.meta.client) {
          throw showError({
            statusMessage: ctx.response._data.message,
            statusCode: ctx.response.status,
            fatal: true,
          });
        } else {
          throw createError({
            statusCode: ctx.response.status,
            message: ctx.response._data.message,
            fatal: true,
          });
        }
      },
    });
  };

  const requestProductsByIDs = (goodIDs: string[]) => {
    const key = goodIDs.sort().join("-");
    const transformProductsByIds = (
      data: IOriginalProductsByIdsResponse,
      currentCategoryId?: number | string,
    ): ITransformedProductsByIdsResponse => {
      const appendUrl = (
        product: IGoodsProduct,
        categoryId?: number | string,
      ) => {
        let url = `${Routes.Product}/${product.id}-${product.code}?shopCode=${storesStore.requestShopCode}&shopType=${API_REQUEST_SHOP_TYPE}`;
        if (categoryId) url += `&category=${categoryId}`;
        return { ...product, url };
      };

      return {
        goods:
          data.goods
            ?.map((product) => appendUrl(product, currentCategoryId))
            .map((product) => formatGoodsProduct(product)) || [],
      };
    };

    const props = {
      goodIDs,
      shopType: String(API_REQUEST_SHOP_TYPE),
      storeCode: storesStore.requestShopCode,
    };
    return useTransport<IOriginalProductsByIdsResponse, ITransformedProductsByIdsResponse>(
      urls.goods.productsByIDs,
      {
        method: "POST",
        body: computed(() => props),
        transform: transformProductsByIds,
        key,
      },
    );
  };

  const requestFilters = (params: IRequestGoodsFilters, key?: string) => {
    return useTransport<IGoodsFilters>(urls.goods.filters, {
      method: "POST",
      body: computed(() => (
        {
          ...params,
          catalogType: String(toValue(params).catalogType || API_CATALOG_TYPE),
          storeType: String(API_REQUEST_SHOP_TYPE),
        }
      )),
      key,
    });
  };

  return {
    requestProducts,
    requestProductById,
    requestProductsByIDs,
    requestFilters,
  };
};
